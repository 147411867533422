import { $, addAction, INIT } from '@situation/setdesign.util';
import TriggeredAnimation from '../util/TriggeredAnimation';

/**
 * Initialize triggered animations based on visibility thresholds.
 *
 * How it works:
 * 1. This script scans for elements with classes like `trigger--5`, `trigger--50`, etc.,
 *    where the number after the dash represents the visibility threshold as a percentage of height
 * 2. When the page is initialized, animations are attached to all matching elements
 *    using the `TriggeredAnimation.create` method.
 * 3. The animations are activated when the element becomes visible by the specified threshold.
 *
 * Animation Options:
 * - `delay`: The delay before the animation starts, in milliseconds (default: 250ms).
 * - `easing`: Animation easing factor (default: 0 for linear easing).
 * - `visibilityThreshold`: The percentage of the element's visibility before the animation
 *    is triggered.
 *
 * Markup Usage:
 * - Add classes like `trigger--5`, `trigger--50`, etc., to elements in your HTML to define the
 *   visibility threshold.
 * - Example:
 *   ```html
 *   <div class="trigger trigger--5">This animates when 5% visible</div>
 *   <div class="trigger trigger--50">This animates when 50% visible</div>
 *   ```
 * - Combine with a third class to define a specific animation effect (e.g., `trigger--slide-in`).
 *   ```html
 *   <div class="trigger trigger--50 trigger--slide-in">Sliding animation at 50% visibility</div>
 *   ```
 *
 * Requirements:
 * - Ensure the required CSS classes (e.g., `trigger--slide-in.active`, `trigger--slide-in`) are
 *   defined in your stylesheets.
 * - Include this script as part of your page initialization to activate the functionality.
 */
addAction(INIT, () => {
    // Iterate through visibility thresholds from 0 to 100 in steps of 5
    for (let threshold = 0; threshold <= 100; threshold += 5) {
        const visibilityThreshold = threshold / 100;
        const selector = `.trigger--${threshold}`;
        const $elements = $(selector);
        // If elements matching the selector exist, create the triggered animation
        if ($elements.length) {
            TriggeredAnimation.create($elements, 'active', {
                delay: 250,
                easing: 0,
                visibilityThreshold,
            });
        }
    }
});
