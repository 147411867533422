import { addFilter } from '@situation/setdesign.util';

addFilter('content-slider/owl-settings', (settings) => ({
    ...settings,
    autoPlay: false,
    autoHeight: true,
    navText: [
        '<span class="icon"><svg><use xlink:href="#icon-long-arrow"></use></svg></span>',
        '<span class="icon"><svg><use xlink:href="#icon-long-arrow"></use></svg></span>',
    ],
}));
